export const lightTheme = {
  body: 'rgb(255, 255, 255)',
  text: 'rgb(0, 0, 0)',
  background: 'rgb(54, 53, 55)',
}
export const darkTheme = {
  body: 'rgb(54, 53, 55)',
  text: 'rgb(250, 250, 250)',
  background: 'rgb(153, 153, 153)',
}

